import React from "react";
import BottomNavbar from "../components/Navbars/BottomNavbar.jsx";
import UserInformation from "../components/LobbyMenu/UserInformation.jsx";
import ServiceList from "../components/LobbyMenu/ServiceList.jsx";
import Upcoming from "../components/UpcomingHistory/Carousels/Upcoming.jsx";
import History from "../components/UpcomingHistory/Carousels/History.jsx";
import Contact from "../components/CTA/Contact.jsx";
// import FAQ from "../components/CTA/FAQ.jsx";

import moment from "moment/moment";

import BannerHomepage from "../assets/img/banner-homepage.png";
import MascotFooter from "../assets/img/mascot-footer.png";
import { Image, Flex, Text } from "@chakra-ui/react";

function Index() {
  moment.locale();

  return (
    <Flex direction="column" paddingBottom="340px">
      <Flex
        gap="24px"
        alignItems="center"
        color="rgba(0, 0, 0, 0.8)"
        bgColor="#EBF3FF"
        padding="24px 16px 16px"
        direction="column"
      >
        <Image
          width="100%"
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          alt="Homepage"
          src={BannerHomepage}
        />
      </Flex>

      <UserInformation />

      <Flex width="100%" direction="column" rowGap="24px" marginTop="24px">
        <ServiceList />
        <Upcoming />
        <History />
        <Contact />
        {/* <FAQ /> */}
      </Flex>

      <Flex
        position="absolute"
        bottom="0px"
        width="100%"
        gap="24px"
        alignItems="center"
        color="#222222"
        bgColor="#EBF3FF"
        padding="32px 16px 102px 16px"
        direction="column"
      >
        <Image
          width="125px"
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          alt="Homepage"
          src={MascotFooter}
        />
        <Flex direction="column" rowGap="4px" alignItems="center">
          <Text fontSize="12px" fontWeight="400">
            Paraspace
          </Text>
          <Text fontSize="12px" fontWeight="400">
            © PT Paragon Technology and Innovation
          </Text>
        </Flex>
      </Flex>

      <BottomNavbar />
    </Flex>
  );
}

export default Index;
