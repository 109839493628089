import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    useDisclosure, useToast,
    VStack,
} from "@chakra-ui/react";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import id from "date-fns/locale/id";
import {
  MdAccessTime,
  MdArrowForward,
  MdOutlineDateRange,
} from "react-icons/md";

import { format } from "date-fns";
import React, { useState } from "react";
import {
  ACTIVE_BUTTON_COLOR,
  Session_Availalbe_Car,
} from "../../../utils/constant";
import { useHistory } from "react-router";
import { useGetQueryParams } from "../../../hooks/useGetQueryParams";
import TimePicker from "../MainCar/Modal/TimePicker";
import useLocalStorage from "../../../hooks/useLocalStorage";

const FormDate = ({ onClose: onCloseChangeDate }) => {
  const { params } = useGetQueryParams();

  const { replace } = useHistory();

  const [bookingDate, setBookingDate] = useState(
    () => new Date(decodeURIComponent(params.booking_date))
  );
  const [startUsage, setStartUsage] = useState(params.start_usage);
  const [finishUsage, setFinishUsage] = useState(params.finish_usage);
  const [, setLocal] = useLocalStorage(Session_Availalbe_Car, {});
  registerLocale("id", id);
  const toast = useToast();
  const formatDate = (date) => {
    return format(date, "EEEE, dd MMMM yyyy", { locale: id });
  };

  const handleStartUsageTime = (selectedTime) => {
    setStartUsage(selectedTime);
    onOpenFinishUsage();
  };
  const handleFinishUsageTime = (selectedTime) => {
    setFinishUsage(selectedTime);
  };
  const {
    isOpen: isOpenStartUsage,
    onOpen: onOpenStartUsage,
    onClose: onCloseStartUsage,
  } = useDisclosure();

  const {
    isOpen: isOpenFinishUsage,
    onOpen: onOpenFinishUsage,
    onClose: onCloseFinishUsage,
  } = useDisclosure();
  return (
    <Box>
      <VStack spacing={4}>
        <FormControl isRequired>
          <FormLabel>Tanggal Booking</FormLabel>
          <InputGroup
            bg="white"
            sx={{
              ".react-datepicker-wrapper": {
                width: "100%",
              },
              ".react-datepicker__tab-loop": {
                zIndex: 3,
              },
            }}
          >
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={MdOutlineDateRange} color="#222222" />}
            />
            <DatePicker
              selected={bookingDate}
              onChange={(date) => setBookingDate(date)}
              dateFormat="eeee, dd MMMM yyyy"
              defa
              placeholderText="Pilih tanggal"
              customInput={
                <Input
                  value={params ? formatDate(bookingDate) : ""}
                  boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                  border="none"
                  paddingLeft="32px"
                />
              }
              minDate={new Date()}
              maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
              locale="id"
              zIndex={3}
            />
          </InputGroup>
        </FormControl>

        <Flex
          width="100%"
          alignItems="flex-end"
          justifyContent="space-between"
          gap="8px"
          flexDir="row"
        >
          <FormControl isRequired>
            <FormLabel>Jam Pergi</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={MdAccessTime} color="#222222" />}
              />
              <Input
                bg="white"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                borderRadius="8px"
                padding="8px"
                value={startUsage}
                onClick={onOpenStartUsage}
                isReadOnly
                cursor="pointer"
                paddingLeft="32px"
                placeholder="Pilih Jam"
              />
            </InputGroup>
            <TimePicker
              isOpen={isOpenStartUsage}
              onClose={onCloseStartUsage}
              onTimeSelect={handleStartUsageTime}
              type="start"
              finishUsage={finishUsage}
            />
          </FormControl>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
            borderRadius="100%"
            padding="8px"
            bg="white"
            marginBottom="4px"
          >
            <MdArrowForward size={16} />
          </Box>

          <FormControl isRequired>
            <FormLabel>Jam Pulang</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={MdAccessTime} color="#222222" />}
              />
              <Input
                bg="white"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                borderRadius="8px"
                padding="8px"
                value={finishUsage}
                onClick={onOpenFinishUsage}
                isReadOnly
                cursor="pointer"
                paddingLeft="32px"
                placeholder="Pilih Jam"
              />
            </InputGroup>
            <TimePicker
              isOpen={isOpenFinishUsage}
              onClose={onCloseFinishUsage}
              onTimeSelect={handleFinishUsageTime}
              type="finish"
              startUsage={startUsage}
            />
          </FormControl>
        </Flex>
      </VStack>

      <Flex alignItems="center" justifyContent="end" gap="16px" mt="48px">
        <Button
          border="1px"
          borderColor={ACTIVE_BUTTON_COLOR}
          padding="6px 12px"
          borderRadius="100px"
          fontWeight={400}
          fontSize="14px"
          letterSpacing="0.1px"
          color={ACTIVE_BUTTON_COLOR}
          variant="outline"
          onClick={onCloseChangeDate}
          width="114px"
          height="32px"
        >
          Batalkan
        </Button>
        <Button
          fontSize="14px"
          bgColor={ACTIVE_BUTTON_COLOR}
          color="white"
          borderColor={ACTIVE_BUTTON_COLOR}
          border="1px"
          padding="6px 12px"
          borderRadius="100px"
          fontWeight={400}
          width="114px"
          height="32px"
          onClick={() => {
            const filter = { ...params };

            if (params.license_plate_type) {
              filter["license_plate_type"] =
                new Date(bookingDate).getDate() % 2 === 0 ? "genap" : "ganjil";
            }
            if (bookingDate) {
              filter["booking_date"] = format(bookingDate, "yyyy-MM-dd");
            }
            if (startUsage) {
              filter["start_usage"] = startUsage;
            }
            if (finishUsage) {
              filter["finish_usage"] = finishUsage;
            }

            const localDate = new Date(filter["booking_date"]);
            const currentDate = new Date();
            const currentTime = new Date().toLocaleTimeString("id-ID", { hour: "2-digit", minute: "2-digit" }).split(".");

            // kondisi untuk mengecek apakah jam yang dipilih lebih kecil dari jam saat ini
            const isTimeLessThanCurrentTime = (
                currentDate.getDay()===localDate.getDay()&&
                currentDate.getMonth()===localDate.getMonth()&&
                currentDate.getFullYear()===localDate.getFullYear()&&
                ((
                    currentTime[0]===filter["start_usage"].split(":")[0]&&
                    currentTime[1]>filter["start_usage"].split(":")[1]
                ) ||
                    currentTime[0]>filter["start_usage"].split(":")[0]
                )
            )

            if (isTimeLessThanCurrentTime) {
              toast({
                title: "Jam tidak valid",
                description: "Jam pergi tidak boleh melewati jam saat ini",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
                containerStyle: {
                  color: "white",
                },
              });
              return;
            }

            setLocal(filter);
            replace({
              pathname: "/booking-car/available-car",
              search: new URLSearchParams(filter).toString(),
            });

            onCloseChangeDate();
          }}
          letterSpacing="0.1px"
        >
          Terapkan
        </Button>
      </Flex>
    </Box>
  );
};

export default FormDate;
