import React, { useState } from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { SignIn } from "../../../utils/signin";
import {
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import LoginImg from "../../../assets/img/login.svg";
import SplashLogin from "../../../components/SplashLogin";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [authData, setAuthData] = useState({
    emailUser: "",
    passUser: "",
  });

  const toast = useToast({
    containerStyle: {
      maxWidth: "100%",
    },
  });

  const ParseError = (response) => {
    if (response) {
      if (response.data.errors) {
        if (response.data.errors[0].message) {
          return response.data.errors[0].message;
        } else {
          return response.data.errors[0];
        }
      } else if (response.status || response.statusText) {
        return response.status + response.statusText;
      } else if (typeof response === "string") {
        return response;
      } else {
        return "Terjadi kesalahan, silahkan coba beberapa saat lagi";
      }
    } else {
      return "Terjadi kesalahan, silahkan coba beberapa saat lagi";
    }
  };

  const handleChange = (e) => {
    setAuthData({ ...authData, [e.target.name]: e.target.value });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = () => {
    setIsLoading(true);

    if (
      authData.emailUser &&
      authData.passUser &&
      !authData.emailUser.includes("paracorpgroup")
    ) {
      SignIn(authData.emailUser, authData.passUser)
        .then(async (res) => {
          if (res.data.errorStatus) {
            setIsLoading(false);
            toast({
              title: "Terjadi Kesalahan",
              description: "Akun belum terdaftar, mohon hubungi Service Desk",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          } else {
            if (
              authData.emailUser.includes("pti-cosmetics.com") ||
              authData.emailUser.includes("paramaglobalinspira.com")
            ) {
              await axios.post(`${process.env.REACT_APP_AUTH}/login`, {
                email: authData.emailUser,
                password: authData.passUser,
              })
              .then(() => {
                _loginParaspace(res, authData.emailUser)
              })
              .catch((error) => {
                toast({
                  title: "Terjadi Kesalahan",
                  description:
                    `${ParseError(error?.response) || "Mohon dicoba lagi"} - GMS`,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                  position: "top",
                });
              });
            } else {
              _loginParaspace(res, authData.emailUser)
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast({
            title: "Terjadi Kesalahan",
            description: ParseError(error?.response) || "Mohon dicoba lagi",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        });
    } else {
      setIsLoading(false);
      toast({
        title: "Terjadi Kesalahan",
        description: "Silahkan masukkan Email pti-cosmetics dan Password",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const _loginParaspace = async (res, emailUser) => {
    await axios.get(
      `${process.env.REACT_APP_DEV_URL}/user?user_email=${emailUser}`,
      {
        headers: {
          Authorization: `Bearer ${res.data.data.token}`,
        },
      }
    )
    .then((getParaspaceUserDataResult) => {
      let paraspaceUserData = getParaspaceUserDataResult.data.data.data[0];
      localStorage.setItem("login", "true");
      localStorage.setItem("paraspaceToken", res.data.data.token);
      localStorage.setItem(
        "id_user_parastay",
        res.data.data.data.id_user_parastay
      );
      localStorage.setItem(
        "id_user",
        paraspaceUserData.user_id
      );
      localStorage.setItem(
        "username",
        paraspaceUserData.user_name
      );
      localStorage.setItem(
        "region_user",
        paraspaceUserData.work_location_id_fk
      );
      localStorage.setItem(
        "role_user",
        paraspaceUserData.user_role
      );
      localStorage.setItem(
        "user_data",
        JSON.stringify(paraspaceUserData)
      );
      window.location.assign("/");
    })
    .catch((error) => {
      setIsLoading(false);
      toast({
        title: "Terjadi Kesalahan",
        description: `${ParseError(error?.response) || "Mohon dicoba lagi"} - PRSPC`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    });
  }

  return (
    <>
      <SplashLogin />
      <Flex
        direction="column"
        px="16px"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Flex justifyContent="center" padding="36px">
          <Image width="222px" alt="Login" src={LoginImg} />
        </Flex>
        <Flex
          width="328px"
          height="fit-content"
          alignItems="center"
          bgColor="#F6FAFF"
          padding="24px"
          direction="column"
          borderRadius="12px"
          boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
        >
          <InputGroup
            style={{ display: "Flex", flexDirection: "column", gap: "24px" }}
          >
            <Flex direction="column">
              <Text
                style={{
                  marginBottom: "8px",
                }}
              >
                Email
              </Text>
              <Flex className="control">
                <Input
                  type="email"
                  placeholder="Email PTI"
                  name="emailUser"
                  value={authData.emailUser}
                  onChange={handleChange}
                  className="input"
                  _placeholder={{ fontSize: "14px", color: "#ADADAD" }}
                  backgroundColor="white"
                  borderRadius="8px"
                />
              </Flex>
            </Flex>
            <Flex direction="column">
              <Text
                style={{
                  marginBottom: "8px",
                }}
              >
                Password
              </Text>
              <Flex
                className="control"
                style={{
                  position: "relative",
                }}
              >
                <Input
                  type={isShowPassword ? "text" : "password"}
                  name="passUser"
                  value={authData.passUser}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Password PTI"
                  className="input"
                  _placeholder={{ fontSize: "14px", color: "#ADADAD" }}
                  backgroundColor="white"
                  borderRadius="8px"
                />

                <InputRightElement
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  style={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={isShowPassword ? faEyeSlash : faEye}
                    size="sm"
                  />
                </InputRightElement>
              </Flex>
            </Flex>
            <Button
              background="#184973"
              color="#FFFFFF"
              onClick={handleLogin}
              isLoading={isLoading}
              borderRadius="100px"
              style={{ fontWeight: 500 }}
            >
              Login
            </Button>
          </InputGroup>
        </Flex>
      </Flex>
      <Flex
        position="fixed"
        bottom="0"
        width="100%"
        maxWidth="500px"
        height="36px"
        justifyContent="center"
        alignItems="center"
        fontSize="12px"
        fontWeight={400}
      >
        © PT Paragon Technology and Innovation
      </Flex>
    </>
  );
}

export default Login;
