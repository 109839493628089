import React, { useState } from "react";
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  HStack,
  NumberInput,
  NumberInputField,
  ModalHeader,
  useToast,
} from "@chakra-ui/react";

const TimePicker = ({
  isOpen,
  onClose,
  onTimeSelect,
  type,
  startUsage,
  finishUsage,
}) => {
  const toast = useToast();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const format = (value) => {
    return String(value).padStart(2, "0");
  };

  const handleSave = () => {
    const time = `${format(hours)}:${format(minutes)}`;

    if (type === "finish" && startUsage) {
      const startTimeInMinutes = startUsage.split(":").reduce((acc, time) => {
        return acc * 60 + parseInt(time);
      }, 0);
      const finishTimeInMinutes = hours * 60 + minutes;

      if (finishTimeInMinutes <= startTimeInMinutes) {
        toast({
          title: "Jam tidak valid",
          description: "Jam pulang harus lebih besar dari jam pergi",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
          containerStyle: {
            color: "white",
          },
        });
      } else {
        onTimeSelect(time);
        onClose();
      }
    } else if (type === "start" && finishUsage) {
      const finishTimeInMinutes = finishUsage.split(":").reduce((acc, time) => {
        return acc * 60 + parseInt(time);
      }, 0);
      const startTimeInMinutes = hours * 60 + minutes;

      if (startTimeInMinutes >= finishTimeInMinutes) {
        toast({
          title: "Jam tidak valid",
          description: "Jam pergi harus lebih kecil dari jam pulang",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
          containerStyle: {
            color: "white",
          },
        });
      } else {
        onTimeSelect(time);
        onClose();
      }
    } else {
      onTimeSelect(time);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%">
        <ModalHeader
          bg="#EBF3FF"
          textAlign="center"
          borderRadius="12px 12px 0 0"
        >
          {type === "start" ? "Jam Pergi" : "Jam Pulang"}
        </ModalHeader>
        <ModalBody padding="24px">
          <HStack spacing={4} alignItems="flex-start">
            <Flex direction="column" gap="4px">
              <NumberInput
                value={format(hours)}
                onChange={(valueString) => {
                  if (parseInt(valueString) > 23) {
                    setHours(23);
                  } else if (
                    (parseInt(valueString) < 0) |
                    (valueString === "")
                  ) {
                    setHours(0);
                  } else {
                    setHours(parseInt(valueString));
                  }
                }}
                max={23}
                min={0}
                bg="#EBF3FF"
                borderRadius="8px"
              >
                <NumberInputField
                  textAlign="center"
                  padding="9px 16px"
                  h="72px"
                  fontSize="45px"
                  fontWeight="400"
                />
              </NumberInput>
              <Text fontSize="14px" fontWeight="400">
                Jam
              </Text>
            </Flex>
            <Text fontSize="57px" fontWeight="400" lineHeight="72px">
              :
            </Text>
            <Flex direction="column" gap="4px">
              <NumberInput
                value={format(minutes)}
                onChange={(valueString) => {
                  if (parseInt(valueString) > 59) {
                    setMinutes(59);
                  } else if (
                    (parseInt(valueString) < 0) |
                    (valueString === "")
                  ) {
                    setMinutes(0);
                  } else {
                    setMinutes(parseInt(valueString));
                  }
                }}
                max={59}
                min={0}
                bg="#EBF3FF"
                borderRadius="8px"
              >
                <NumberInputField
                  textAlign="center"
                  padding="9px 16px"
                  h="72px"
                  fontSize="45px"
                  fontWeight="400"
                />
              </NumberInput>
              <Text fontSize="14px" fontWeight="400">
                Menit
              </Text>
            </Flex>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} color="#34618D" colorScheme="white">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="#34618D"
            ml={3}
            colorScheme="white"
          >
            {type === "start" ? "Next" : "Ok"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TimePicker;
