import React, { useState, useEffect } from "react";
import Notification from "./Notification";
import Axios from "../../utils/axiosService";
import { Box, Flex, Text } from "@chakra-ui/react";

function UserInformation() {
  const [userName, setUserName] = useState("");

  const logoutHandler = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("region_user");
    localStorage.removeItem("role_user");
    localStorage.removeItem("username");
    localStorage.removeItem("id_user");
    localStorage.removeItem("paraspaceToken");
    localStorage.removeItem("user_data");
    window.location.assign("/auth/login");
  };

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("user_data"));

    Axios({
      method: "GET",
      url: `/user?user_name=${userData?.user_name}${
        userData?.user_email && `&user_email=${userData?.user_email}`
      }`,
    })
      .then((result) => {
        if (result.status === 200) {
          setUserName(result.data.data.data[0]?.user_name);
        }
      })
      .catch(() => {
        logoutHandler();
      });
  }, []);

  return (
    <Flex
      h="77px"
      width="100%"
      padding="8px 16px"
      position="relative"
      backgroundImage={`linear-gradient(to bottom, #EBF3FF 50%, #FFFFFF 50%)`}
    >
      <Box
        position="relative"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="100%"
      >
        <Box
          width="100%"
          padding="8px 12px"
          bg="#FFFFFF"
          style={{
            boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
            borderRadius: "12px",
          }}
        >
          <Flex justify="space-between" align="center">
            <Flex direction="column">
              <Text fontSize="14px">Hi, </Text>
              <Text fontSize="16px" fontWeight="600">
                {userName}
              </Text>
            </Flex>
            <Flex
              height="32px"
              width="32px"
              borderRadius="100%"
              border="1px solid #E9E9E9"
              align="center"
              justify="center"
            >
              <Notification />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default UserInformation;
