import { Button, Flex, Text, Grid, GridItem } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { MdMeetingRoom, MdDirectionsCar, MdHouse, MdHelpOutline, MdSearch } from "react-icons/md";

const ServiceData = [
  {
    name: "Booking Room",
    id: 2,
    link: "/booking-room",
    icon: <MdMeetingRoom size="28px" color="#184973" />,
    active: true,
  },
  {
    name: "Booking Car",
    id: 3,
    link: "/booking-car",
    icon: <MdDirectionsCar size="28px" color="#184973" />,
    active: true,
  },
  {
    name: "Booking Mess",
    id: 1,
    link: "/parastay",
    icon: <MdHouse size="28px" color="#184973" />,
    active: true,
  },
  {
    name: "ParaHelp",
    link: "/parastay",
    icon: <MdHelpOutline size="28px" color="#A5A5A5" />,
    active: false,
  },
  {
    name: "Search",
    link: "/",
    icon: <MdSearch size="28px" color="#A5A5A5" />,
    active: false,
  },
];

function ServiceList() {
  const navigate = useHistory();

  return (
    <Grid templateColumns="repeat(3,1fr)" padding="4px 16px" gap="16px">
      {ServiceData.map((service, i) => (
        <GridItem maxHeight="80px">
          <Button
            key={i}
            isDisabled={!service.active}
            style={{
              boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
              borderRadius: "12px",
            }}
            w="100%"
            h="100%"
            padding="0"
            maxHeight="80px"
            bg={service.active ? "#F0F6FF" : "#EFEFEF"}
            onClick={() => {
              localStorage.setItem("appId", service.id);
              navigate.push(service.link);
            }}
          >
            <Flex
              w="100%"
              h="100%"
              padding="10px 8px"
              direction="column"
              rowGap="8px"
              borderRadius="12px"
            >
              {service.icon}
              <Text fontSize="12px" fontWeight="400" textAlign="left" style={{ textWrap: "wrap" }}>
                {service.name}
              </Text>
            </Flex>
          </Button>
        </GridItem>
      ))}
    </Grid>
  );
}

export default ServiceList;
