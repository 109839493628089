import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import {
  faFileAlt,
  faAngleDown,
  faEdit,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import { Flex, Box, Text } from "@chakra-ui/react";
import MainHeader from "../../../../../components/Parastay/Header/MainHeader";
import styles from "./styles.module.css";
import AxiosParastay from "../../../../../utils/parastayAxiosService";

class RequestTrackView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complaint_data: {
        data: [
          {
            complain_id: null,
            location_id_fk2: null,
            requestor_id: null,
            ga_admin_pic_id: null,
            crem_admin_pic_id: null,
            category_id_fk: null,
            sub_location: "",
            complain_desc: "",
            complain_state: "",
            priority: "",
            solution: "",
            complain_request_date: "",
            complain_start_date: "",
            complain_end_date: "",
            complain_status_id: null,
          },
        ],
      },
      complaint_log: [
        {
          complain_log_id: null,
          sender_id: null,
          complain_id_fk2: null,
          complain_status_id: null,
          log: null,
          send_datetime: null,
        },
      ],
      log: null,
      isLoading: true,
      isEmpty: false,
      priority_description: null,
      complain_category: [],
    };
  }

  componentDidMount() {
    this.collapsibles = bulmaCollapsible.attach(".is-collapsible", {
      container: this.refs.collapsibles,
    });

    const priorityA = "Start: Hari H, End: to be confirmed";
    const priorityB =
      "End: H+7 dari tanggal penjadwalan. Penjadwalan pekerjaan disesuaikan dengan kondisi lapangan dan/atau user";
    const priorityC =
      "End: H+14 dari tanggal penjadwalan. Penjadwalan pekerjaan disesuaikan dengan kondisi lapangan dan/atau user";

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const complaintID = urlParams.get("complain_id");

    AxiosParastay({
      method: "GET",
      url: `/UserComplainList?complain_id=${complaintID}`,
    })
    .then((result) => {
      this.setState({
        complaint_data: result.data.data[0],
      });
      if (result.data.data[0].priority === 1) {
        this.setState({
          priority_description: priorityA,
        });
      } else if (result.data.data[0].priority === 2) {
        this.setState({
          priority_description: priorityB,
        });
      } else if (result.data.data[0].priority === 3) {
        this.setState({
          priority_description: priorityC,
        });
      }

      AxiosParastay({
        method: "GET",
        url: `/CategoryComplain`,
      })
      .then((result2) => {
        for (
          let loopOver = 0;
          loopOver < result2.data.data.length;
          loopOver++
        ) {
          if (
            result.data.data[0].category_id_fk ===
            result2.data.data[loopOver].complain_category_id
          ) {
            this.setState({
              complain_category: result2.data.data[loopOver],
            });
          } else {
            // none
          }
        }
      });
    })
    .catch(function (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Cannot Load Complain Data",
        confirmButtonColor: "blue",
        confirmButtonText: `Return`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.assign("./track-requests");
        }
      });
    });

    AxiosParastay({
      method: "GET",
      url: `/ComplainLog?complain_id_fk2=${complaintID}`,
    })
    .then((result) => {
      this.setState({
        complaint_log: result.data.data,
      });
    })
    .catch(function (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Cannot Load Complain Log Data",
        confirmButtonColor: "blue",
        confirmButtonText: `Return`,
      }).then((result) => {
        if (result.isConfirmed) {
          // do nothing
        }
      });
    });

    AxiosParastay({
      method: "GET",
      url: `/ComplainCremPhoto?complain_id=${complaintID}`,
    })
    .then((photoRes) => {
      this.setState({
        setPhotolist: photoRes.data.data,
        isLoading: false,
      });
    })
    .catch(function (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Cannot Load Complain Photo",
        confirmButtonColor: "blue",
        confirmButtonText: `Return`,
      }).then((result) => {
        if (result.isConfirmed) {
          // do nothing
        }
      });
    });
  }

  ComplaintTextStatus(key) {
    let printStatus;
    switch (key) {
      case 1:
        printStatus = "Complaint Submitted";
        break;
      case 2:
        printStatus = "Assignment Process";
        break;
      case 3:
        printStatus = "Complaint Picked";
        break;
      case 4:
        printStatus = "On Progress";
        break;
      case 5:
        printStatus = "Complaint Done";
        break;
      case 6:
        printStatus = "Complaint Declined";
        break;
      case 7:
        printStatus = "Complaint Postponed";
        break;
      default:
        break;
    }

    return printStatus;
  }

  render() {
    let complaintLog = this.state.complaint_log;
    let latestData = complaintLog[complaintLog.length - 1];

    const { isLoading } = this.state;

    return (
      <>
        <Flex className={styles["header-container"]}>
          <MainHeader
            title="Komplain Mess"
            backLink={`/parastay/track-requests`}
          />
        </Flex>
        <Box margin="2em 1.5em">
          <div class="container">
            {isLoading ||
            this.state.complaint_data.complain_id === undefined ? (
              <div className="justify-content-center">
                <Loader
                  className="justify-content-center has-text-centered mt-3"
                  type="Oval"
                  color="#30C1FF"
                  height={100}
                  width={100}
                />
                <span>
                  <h1 className="subtitle has-text-centered">
                    Loading Data...
                  </h1>
                </span>
              </div>
            ) : (
              <>
                <div ref="collapsibles" id="accordion_first">
                  <article class="message">
                    <div
                      class="message-header has-background-gomess-babyblue"
                      href="#complaintData"
                      data-action="collapse"
                    >
                      <div class="icon is-small is-left has-text-primary">
                        <FontAwesomeIcon icon={faFileAlt} size="lg" />
                      </div>
                      <span>
                        <h3 class="subtitle has-text-black">
                          <strong>Detail Komplain</strong>
                        </h3>
                      </span>
                      <a
                        href={() => false}
                        class="icon is-small is-left has-text-primary"
                      >
                        <FontAwesomeIcon icon={faAngleDown} size="lg" />
                      </a>
                    </div>
                    <div
                      id="complaintData"
                      class="message-body is-collapsible has-background-white"
                      data-parent="accordion_first"
                    >
                      <div class="card">
                        <div class="card-content">
                          <p />
                          <div class="media">
                            <div class="media-content">
                              <p class="title is-6">Kategori Lokasi</p>
                              <p class="subtitle is-6">
                                {
                                  this.state.complain_category
                                    .complain_category_name
                                }
                              </p>
                              <p class="title is-6 mt-1">Sub Lokasi</p>
                              <p class="subtitle is-6">
                                {this.state.complaint_data.sub_location}
                              </p>
                              <p class="title is-6 mt-1">Deskripsi Komplain</p>
                              <p
                                class={
                                  this.state.complaint_data.priority
                                    ? "subtitle is-6"
                                    : "subtitle is-6"
                                }
                              >
                                {this.state.complaint_data.complain_desc}
                              </p>
                              {this.state.complaint_data.priority ? (
                                <>
                                  <h2 class="is-size-5 mb-1 has-text-left">
                                    <b>Prioritas </b>
                                    <span />
                                    <button
                                      class="has-tooltip-right-desktop has-tooltip-top-mobile has-tooltip-multiline button is-small is-white"
                                      data-tooltip={
                                        this.state.priority_description
                                      }
                                    >
                                      <div class="icon is-left has-text-primary">
                                        <FontAwesomeIcon
                                          icon={faQuestionCircle}
                                          size="lg"
                                        />
                                      </div>
                                    </button>
                                  </h2>
                                  <p class="subtitle is-5 mb-5 has-text-left">
                                    {this.state.complaint_data.priority === 1
                                      ? "A"
                                      : this.state.complaint_data.priority === 2
                                      ? "B"
                                      : this.state.complaint_data.priority === 3
                                      ? "C"
                                      : null}
                                  </p>
                                </>
                              ) : null}
                              {this.state.setPhotolist !== undefined ? (
                                <>
                                  <p class="title is-6 mt-1 mb-2">Gambar</p>
                                  {this.state.setPhotolist.map((list, idx) => {
                                    return (
                                      <img
                                        alt="Complaint Photos"
                                        top
                                        height="100%"
                                        style={{ maxWidth: "250px" }}
                                        src={`${process.env.REACT_APP_PARASTAY_UP}/${list.photo}`}
                                      />
                                    );
                                  })}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="container has-text-left pb-3 my-3">
                  {this.state.complaint_data.complain_status_id === 1 ? (
                    <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                      <div class="media">
                        <div class="media-content is-align-items-center">
                          <div class="content ">
                            <h1 class="has-text-centered is-size-3 has-text-success">
                              Complaint submitted
                            </h1>
                            <p class="subtitle has-text-centered is-size-5">
                              Komplain anda sudah diterima dan akan segera
                              ditangani
                            </p>
                            {latestData.log !== "" ? (
                              <p class="has-text-centered is-size-6">
                                Komentar: <br />
                                <strong>{latestData.log}</strong>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.complaint_data.complain_status_id === 2 ? (
                    <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                      <div class="media">
                        <div class="media-content is-align-items-center">
                          <div class="content ">
                            <h1 class="has-text-centered is-size-3 has-text-success">
                              Assignment Process
                            </h1>
                            <p class="subtitle has-text-centered is-size-5">
                              Komplain anda telah disetujui dan dilakukan sedang
                              penugasan
                            </p>
                            {latestData.log !== "" ? (
                              <p class="has-text-centered is-size-6">
                                Komentar: <br />
                                <strong>{latestData.log}</strong>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.complaint_data.complain_status_id === 3 ? (
                    <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                      <div class="media">
                        <div class="media-content is-align-items-center">
                          <div class="content ">
                            <h1 class="has-text-centered is-size-3 has-text-success">
                              Picked
                            </h1>
                            <p class="subtitle has-text-centered is-size-5">
                              Komplain anda sudah ditugaskan kepada WPM / CREM
                            </p>
                            {latestData.log !== "" ? (
                              <p class="has-text-centered is-size-6">
                                Komentar: <br />
                                <strong>{latestData.log}</strong>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.complaint_data.complain_status_id === 4 ? (
                    <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                      <div class="media">
                        <div class="media-content is-align-items-center">
                          <div class="content ">
                            <h1 class="has-text-centered is-size-3 has-text-success">
                              On Process
                            </h1>
                            <p class="subtitle has-text-centered is-size-5">
                              Komplain akan segera ditangani oleh WPM / CREM
                            </p>
                            {latestData.log !== "" ? (
                              <p class="has-text-centered is-size-6">
                                Komentar: <br />
                                <strong>{latestData.log}</strong>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.complaint_data.complain_status_id === 6 ? (
                    <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                      <div class="media">
                        <div class="media-content is-align-items-center">
                          <div class="content ">
                            <h1 class="has-text-centered is-size-3 has-text-danger">
                              Declined
                            </h1>
                            {latestData.log !== "" ? (
                              <p class="has-text-centered is-size-6">
                                Alasan Penolakan: <br />
                                <strong>{latestData.log}</strong>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.complaint_data.complain_status_id === 7 ? (
                    <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                      <div class="media">
                        <div class="media-content is-align-items-center">
                          <div class="content ">
                            <h1 class="has-text-centered is-size-3 has-text-gomess-grey">
                              Postponed
                            </h1>
                            <p class="subtitle has-text-centered is-size-5">
                              Maaf, kami tunda komplain anda, mohon ditunggu
                              kabar selanjutnya
                            </p>
                            {latestData.log !== "" ? (
                              <p class="has-text-centered is-size-6">
                                Komentar: <br />
                                <strong>{latestData.log}</strong>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.complaint_data.complain_status_id === 5 ? (
                    <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                      <div class="media">
                        <div class="media-content is-align-items-center">
                          <div class="content ">
                            <h1 class="has-text-centered is-size-3 has-text-gomess-positivestate">
                              Done
                            </h1>
                            <p class="subtitle has-text-centered is-size-5">
                              Komplain anda telah selesai kami tangani
                            </p>
                            {latestData.log !== "" ? (
                              <p class="has-text-centered is-size-6">
                                Komentar: <br />
                                <strong>{latestData.log}</strong>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div class="card mt-5">
                    <div class="card-content">
                      <div class="media">
                        <div class="media-content">
                          <p class="subtitle has-text-left has-text-gomess-grey is-size-6">
                            Request ID #{this.state.complaint_data.complain_id}
                          </p>
                          <ul class="steps is-dashed is-vertical">
                            {this.state.complaint_log.map((data, index) => {
                              var options = {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              };

                              const formatter = new Intl.DateTimeFormat(
                                "id",
                                options
                              );
                              let SDDate = new Date(data.send_datetime);
                              const finalSDDate = formatter.format(SDDate);

                              return (
                                <li key={index} class="steps-segment">
                                  <span class="steps-marker">
                                    <div class="icon is-left has-text-white">
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        size="md"
                                      />
                                    </div>
                                  </span>
                                  <div class="steps-content">
                                    <Text
                                      fontSize="18px"
                                      fontWeight="600"
                                    >
                                      {this.ComplaintTextStatus(data.complain_status_id)}
                                    </Text>
                                    <Text fontSize="14px" mb="0.5em">
                                      {finalSDDate}
                                    </Text>
                                    {data.log !== "" && (
                                      <Text fontSize="14px" fontWeight="500">
                                        Komentar:{" "}
                                        <Text fontSize="14px" mb="0.5em" fontWeight="400">{data.log}</Text>
                                      </Text>
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      </>
    );
  }
}

export default RequestTrackView;
