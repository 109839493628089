import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Spinner,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { format } from "date-fns";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TimePicker from "./Modal/TimePicker";

import {
  MdAccessTime,
  MdArrowForward,
  MdOutlineDateRange,
} from "react-icons/md";
import Axios from "../../../utils/axiosService";
import { Session_Availalbe_Car } from "../../../utils/constant";
import { getListBookedCarDate } from "../../../api/fetchBookedCar";

const BookingForm = ({ isBookingCar, idCar }) => {
  const [excludeDate, setExcludeDate] = useState([]);
  useEffect(() => {
    const fetchDataCarBooked = async () => {
      const dateBooked = await getListBookedCarDate({ id: idCar });
      setExcludeDate(dateBooked);
    };
    fetchDataCarBooked();
  }, [idCar]);
  const navigate = useHistory();
  const toast = useToast();

  const [bookingDate, setBookingDate] = useState("");
  const [startUsage, setStartUsage] = useState("");
  const [finishUsage, setFinishUsage] = useState("");
  const [purpose, setPurpose] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  registerLocale("id", id);
  const formatDate = (date) => {
    return format(date, "EEEE, dd MMMM yyyy", { locale: id });
  };

  const {
    isOpen: isOpenStartUsage,
    onOpen: onOpenStartUsage,
    onClose: onCloseStartUsage,
  } = useDisclosure();
  const {
    isOpen: isOpenFinishUsage,
    onOpen: onOpenFinishUsage,
    onClose: onCloseFinishUsage,
  } = useDisclosure();

  const handleStartUsageTime = (selectedTime) => {
    setStartUsage(selectedTime);
    onOpenFinishUsage();
  };
  const handleFinishUsageTime = (selectedTime) => {
    setFinishUsage(selectedTime);
  };

  // value for field
  const [capacityValue, setCapacityValue] = useState("semua");
  const [isOddEven, setIsOddEven] = useState(false);

  const isValidForm =
    bookingDate && startUsage && finishUsage && purpose && capacityValue;

  const handleSubmit = (e) => {
    e.preventDefault();

    const localDate = new Date(bookingDate);
    const timezoneOffset = localDate.getTimezoneOffset() * 60000;
    const formattedDate = new Date(localDate.getTime() - timezoneOffset)
      .toISOString()
      .split("T")[0];
    const currentDate = new Date();
    const currentTime = new Date().toLocaleTimeString("id-ID", { hour: "2-digit", minute: "2-digit" }).split(".");

    // kondisi untuk mengecek apakah jam yang dipilih lebih kecil dari jam saat ini
    const isTimeLessThanCurrentTime = (
        currentDate.getDay()===localDate.getDay()&&
        currentDate.getMonth()===localDate.getMonth()&&
        currentDate.getFullYear()===localDate.getFullYear()&&
        ((
                currentTime[0]===startUsage.split(":")[0]&&
                currentTime[1]>startUsage.split(":")[1]
            ) ||
            currentTime[0]>startUsage.split(":")[0]
        )
    )

    const bookingFormFilterData = {
      booking_date: formattedDate,
      start_usage: startUsage,
      finish_usage: finishUsage,
      purpose: purpose,
    };

    if (isOddEven) {
      bookingFormFilterData["license_plate_type"] =
        bookingDate.getDate() % 2 === 0 ? "genap" : "ganjil";
    }

    if (idCar) {
      bookingFormFilterData["id"] = idCar;
    }

    if (capacityValue.toLowerCase() !== "semua") {
      bookingFormFilterData["capacity"] = Number(capacityValue[0]) || 5;
    }

    if (!isBookingCar) {
      if (isTimeLessThanCurrentTime) {
        toast({
          title: "Jam tidak valid",
          description: "Jam pergi tidak boleh melewati jam saat ini",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
          containerStyle: {
            color: "white",
          },
        });
        return;
      }

      // function yang sekarang di /BookingCar/MainCar/BookingForm.jsx
      localStorage.setItem(
        "bookingFormFilterData",
        JSON.stringify(bookingFormFilterData)
      );

      const baseUrl = "/booking-car/available-car";
      const queryString = new URLSearchParams(bookingFormFilterData).toString();
      const urlWithQuery = `${baseUrl}?${queryString}`;
      navigate.push(urlWithQuery);
    } else {
      if (isTimeLessThanCurrentTime) {
        toast({
          title: "Jam tidak valid",
          description: "Jam pergi tidak boleh melewati jam saat ini",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
          containerStyle: {
            color: "white",
          },
        });
        return;
      }

      // function post handleBookCar yang di SummaryByDate, tapi data yang dipake menyesuaikan
      setIsLoading(true);
      Axios.post("/car/booking", {
        car_id: Number(bookingFormFilterData.id),
        booking_status: "Booking Created",
        car_user_id: parseInt(localStorage.getItem("id_user")),
        purpose: bookingFormFilterData.purpose,
        start_usage:
          bookingFormFilterData.booking_date +
          " " +
          bookingFormFilterData.start_usage,
        finish_usage:
          bookingFormFilterData.booking_date +
          " " +
          bookingFormFilterData.finish_usage,
      })
        .then((res) => {
          toast({
            title: "Berhasil booking mobil",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
            containerStyle: {
              color: "white",
            },
          });
          localStorage.removeItem(Session_Availalbe_Car);
          const bookingId = res.data.data.data.id;
          navigate.push("/booking-car/booking-details/" + bookingId);
        })
        .catch((err) => {
          let errorMessage = "An error has occurred";
          if (
            err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.length > 0
          ) {
            errorMessage = `An error has occurred: ${err.response.data.errors[0].message}`;
          } else if (err.message) {
            errorMessage = `An error has occurred: ${err.message}`;
          }
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
            containerStyle: {
              color: "white",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box width="100%" padding="24px 16px" borderBottom="1px solid #EFEFEF">
      <Box
        bg="#F6FAFF"
        padding="12px"
        borderRadius="12px"
        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
        fontSize="14px"
      >
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel fontSize="14px">Tanggal Booking</FormLabel>
              <InputGroup
                bg="white"
                sx={{
                  ".react-datepicker-wrapper": {
                    width: "100%",
                  },
                  ".react-datepicker__tab-loop": {
                    zIndex: 3,
                  },
                }}
              >
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={MdOutlineDateRange} color="#222222" />}
                />
                <DatePicker
                  selected={bookingDate}
                  onChange={(date) => setBookingDate(date)}
                  dateFormat="eeee, dd MMMM yyyy"
                  excludeDates={excludeDate}
                  placeholderText="Pilih tanggal"
                  customInput={
                    <Input
                      fontSize="14px"
                      value={bookingDate ? formatDate(bookingDate) : ""}
                      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                      border="none"
                      paddingLeft="32px"
                    />
                  }
                  minDate={new Date()}
                  maxDate={
                    new Date(new Date().setMonth(new Date().getMonth() + 1))
                  }
                  locale="id"
                  zIndex={3}
                />
              </InputGroup>
            </FormControl>

            <Flex
              width="100%"
              alignItems="flex-end"
              justifyContent="space-between"
              gap="8px"
              flexDir="row"
            >
              <FormControl isRequired>
                <FormLabel fontSize="14px">Jam Pergi</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={MdAccessTime} color="#222222" />}
                  />
                  <Input
                    bg="white"
                    boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                    borderRadius="8px"
                    padding="8px"
                    value={startUsage}
                    onClick={onOpenStartUsage}
                    isReadOnly
                    cursor="pointer"
                    paddingLeft="32px"
                    placeholder="Pilih Jam"
                    fontSize="14px"
                  />
                </InputGroup>
                <TimePicker
                  isOpen={isOpenStartUsage}
                  onClose={onCloseStartUsage}
                  onTimeSelect={handleStartUsageTime}
                  type="start"
                  finishUsage={finishUsage}
                />
              </FormControl>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                borderRadius="100%"
                padding="8px"
                bg="white"
                marginBottom="4px"
              >
                <MdArrowForward size={16} />
              </Box>

              <FormControl isRequired>
                <FormLabel fontSize="14px">Jam Pulang</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={MdAccessTime} color="#222222" />}
                  />
                  <Input
                    bg="white"
                    boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                    borderRadius="8px"
                    padding="8px"
                    value={finishUsage}
                    onClick={onOpenFinishUsage}
                    isReadOnly
                    cursor="pointer"
                    paddingLeft="32px"
                    placeholder="Pilih Jam"
                    fontSize="14px"
                  />
                </InputGroup>
                <TimePicker
                  isOpen={isOpenFinishUsage}
                  onClose={onCloseFinishUsage}
                  onTimeSelect={handleFinishUsageTime}
                  type="finish"
                  startUsage={startUsage}
                />
              </FormControl>
            </Flex>

            <FormControl isRequired>
              <FormLabel fontSize="14px">Keperluan Booking</FormLabel>
              <Textarea
                onChange={(e) => setPurpose(e.target.value)}
                placeholder="Contoh: market visit, dll"
                maxLength={200}
                bg="white"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                borderRadius="8px"
                gap="8px"
                padding="8px"
                z
                fontSize="14px"
                sx={{
                  "::placeholder": {
                    fontStyle: "italic",
                  },
                }}
              />
            </FormControl>
            {!isBookingCar ? (
              <>
                <FormControl as="fieldset" isRequired>
                  <FormLabel as="legend" fontSize="14px">
                    Kapasitas Mobil
                  </FormLabel>
                  <RadioGroup
                    defaultValue="Semua"
                    onChange={setCapacityValue}
                    sx={{
                      ".chakra-radio__label": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    <HStack align="start">
                      <Box
                        cursor="pointer"
                        bg="white"
                        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                        borderRadius="8px"
                        gap="8px"
                        padding="8px"
                        z
                      >
                        <Radio value="Semua" isFocusable={false}>
                          Semua
                        </Radio>
                      </Box>
                      <Box
                        cursor="pointer"
                        bg="white"
                        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                        borderRadius="8px"
                        gap="8px"
                        padding="8px"
                        z
                      >
                        <Radio value="5 Orang" isFocusable={false}>
                          5 Orang
                        </Radio>
                      </Box>
                      <Box
                        cursor="pointer"
                        bg="white"
                        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                        borderRadius="8px"
                        gap="8px"
                        padding="8px"
                        z
                      >
                        <Radio value="7 Orang" isFocusable={false}>
                          7 Orang
                        </Radio>
                      </Box>
                    </HStack>
                  </RadioGroup>
                </FormControl>

                <FormControl display="flex" flexDirection="column" gap="8px">
                  <Text display="flex" alignItems="center" fontWeight="500">
                    Melewati Ganjil Genap?
                  </Text>

                  <Flex alignItems="center" gap="8px">
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      ccolor="#5E5E5E"
                      m="0"
                    >
                      {isOddEven ? "Ya" : "Tidak"}
                    </FormLabel>

                    <Switch
                      colorScheme="blue"
                      size="md"
                      isChecked={isOddEven}
                      onChange={() => setIsOddEven(!isOddEven)}
                    />
                  </Flex>
                </FormControl>
              </>
            ) : null}

            <Button
              width="100%"
              bg="#184973"
              color="white"
              height="40px"
              borderRadius="100px"
              fontSize="14px"
              fontWeight="500"
              isDisabled={!isValidForm || isLoading}
              type="submit"
            >
              {isBookingCar ? "Book Mobil" : "Cari Mobil"}
              {isLoading && <Spinner size={"sm"} ml="5px" />}
            </Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default BookingForm;
