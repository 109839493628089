import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import Swal from "sweetalert2";
import {
  faInfo,
  faFileAlt,
  faAddressCard,
  faAngleDown,
  faUserFriends,
  faInfoCircle,
  faHotel,
  faSuitcase,
  faClock,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.css";
import Loader from "react-loader-spinner";
import { Flex, Box, Text } from "@chakra-ui/react";
import AxiosParastay from "../../../../../utils/parastayAxiosService";
import MainHeader from "../../../../../components/Parastay/Header/MainHeader";

class BookingTrackView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      booking_data: {
        data: [
          {
            data_id: null,
            room_id_fk: null,
            user_id_fk: null,
            occupant_type: null,
            bring_family: null,
            state: null,
            request_date: null,
            start_date: null,
            end_date: null,
            notes: null,
          },
        ],
      },
      booking_log_data: [
        {
          booking_log_id: null,
          sender_id: null,
          booking_id_fk1: null,
          booking_status_id: null,
          send_datetime: null,
          booking_log: null,
        },
      ],
      mess_data: [],
      booking_log: null,
      isLoading: true,
    };
  }

  BookingTextStatus(key) {
    let printStatus;
    switch (key) {
      case 1:
        printStatus = "Booking Submitted";
        break;
      case 2:
        printStatus = "Booking On Process";
        break;
      case 3:
        printStatus = "Booking On Waiting List";
        break;
      case 4:
        printStatus = "Booking Done";
        break;
      case 5:
        printStatus = "Booking Declined";
        break;
      case 6:
        printStatus = "Settled";
        break;
      case 7:
        printStatus = "Check Out Request Submitted";
        break;
      case 8:
        printStatus = "Move Out Request Submitted";
        break;
      case 9:
        printStatus = "Move Out Approved";
        break;
      case 10:
        printStatus = "Check Out Approved";
        break;
      default:
        break;
    }

    return printStatus;
  }

  componentDidMount() {
    this.collapsibles = bulmaCollapsible.attach(".is-collapsible", {
      container: this.refs.collapsibles,
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const bookingID = urlParams.get("booking_id");

    AxiosParastay({
      method: "GET",
      url: `/UserBookingData?data_id=${bookingID}`,
    })
      .then((result) => {
        this.setState({
          booking_data: result.data.data[0],
        });
        AxiosParastay({
          method: "GET",
          url: `/MessInfo?room_name=${result.data.data[0].room_name}`,
        }).then((result) => {
          this.setState({
            mess_data: result.data.data[0],
          });
        });
      })
      .catch(function (error) {
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          icon: "error",
          title: "Server Error: Cannot Load Booking Data",
          confirmButtonColor: "blue",
          confirmButtonText: `Return`,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.assign("./track-requests");
          }
        });
      });

    AxiosParastay({
      method: "GET",
      url: `/UserBookingLog?booking_id=${bookingID}`,
    })
      .then((result) => {
        this.setState({
          booking_log_data: result.data.data,
          isLoading: false,
        });
      })
      .catch(function (error) {
        this.setState({
          isLoading: false,
        });
        // do nothing...yet
      });
  }

  render() {
    let bookingLog = this.state.booking_log_data;
    let bookingData = this.state.booking_data;

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let checkInDate = new Date(this.state.booking_data.start_date);
    let finalCheckInDate = checkInDate.toLocaleDateString("id", options);

    let finalCheckOutDate = null;
    if (this.state.booking_data.end_date !== null) {
      let checkOutDate = new Date(this.state.booking_data.end_date);
      finalCheckOutDate = checkOutDate.toLocaleDateString("id", options);
    } else if (this.state.booking_data.end_date === null) {
      finalCheckOutDate = null;
    }

    let latestData = bookingLog[bookingLog.length - 1];
    const { isLoading } = this.state;

    return (
      <>
        <Flex className={styles["header-container"]}>
          <MainHeader title="Permohonan Mess" backLink={`/parastay/track-requests`} />
        </Flex>
        <Box margin="2em 1.5em">
          <div class="container">
            {isLoading || bookingData.data_id === undefined ? (
              <Loader type="ThreeDots" color="#30C1FF" height={100} width={100} />
            ) : (
              <>
                <div ref="collapsibles" id="accordion_first">
                  <article class="message">
                    <div
                      class="message-header has-background-gomess-babyblue"
                      href="#sk-reservasi-mess"
                      data-action="collapse"
                    >
                      <div class="icon is-small is-left has-text-primary">
                        <FontAwesomeIcon icon={faFileAlt} size="lg" />
                      </div>
                      <span>
                        <h3 class="subtitle has-text-black">
                          <strong>Detail Permohonan</strong>
                        </h3>
                      </span>
                      <a href={() => false} class="icon is-small is-left has-text-primary">
                        <FontAwesomeIcon icon={faAngleDown} size="lg" />
                      </a>
                    </div>
                    <div
                      id="sk-reservasi-mess"
                      class="message-body is-collapsible has-background-white"
                      data-parent="accordion_first"
                    >
                      <div>
                        <div class="card">
                          <div class="card-content">
                            <p />
                            {this.state.booking_data.room_name !== null ? (
                              <>
                                <div class="media">
                                  <div class="media-left">
                                    <div class="icon is-left has-text-primary">
                                      <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                    </div>
                                  </div>
                                  <div class="media-content">
                                    <p class="title is-5">Penempatan Mess</p>
                                  </div>
                                </div>
                                <p />
                                <div class="media">
                                  {this.state.booking_data.occupant_type === 1 ? (
                                    <>
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faUserFriends} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Jenis Mess:</p>
                                        <p class="subtitle is-6">Single</p>
                                      </div>
                                    </>
                                  ) : this.state.booking_data.occupant_type === 2 ? (
                                    <>
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faUserFriends} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Jenis Mess:</p>
                                        <p class="subtitle is-6">Couple</p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                                <p />
                                {this.state.booking_data.staying_type === 1 ? (
                                  <div class="media">
                                    <div class="media-left">
                                      <div class="icon is-left has-text-primary">
                                        <FontAwesomeIcon icon={faSuitcase} size="md" />
                                      </div>
                                    </div>
                                    <div class="media-content">
                                      <p class="title is-6">Jenis Penempatan:</p>
                                      <p class="subtitle is-6">Visit</p>
                                    </div>
                                  </div>
                                ) : this.state.booking_data.staying_type === 2 ? (
                                  <>
                                    <div class="media">
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faSuitcase} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Jenis Penempatan:</p>
                                        <p class="subtitle is-6">Settle</p>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                <p />
                                <div class="media">
                                  <div class="media-left">
                                    <div class="icon is-left has-text-primary">
                                      <FontAwesomeIcon icon={faHotel} size="md" />
                                    </div>
                                  </div>
                                  <div class="media-content">
                                    <p class="title is-6">Lokasi Mess:</p>
                                    <p class="subtitle is-6">
                                      {this.state.booking_data.location_name}
                                    </p>
                                  </div>
                                </div>
                                <p />
                                <div class="media">
                                  <div class="media-left">
                                    <div class="icon is-left has-text-primary">
                                      <FontAwesomeIcon icon={faDoorOpen} size="md" />
                                    </div>
                                  </div>
                                  <div class="media-content">
                                    <p class="title is-6">Kamar Mess:</p>
                                    <p class="subtitle is-6">{this.state.booking_data.room_name}</p>
                                  </div>
                                </div>
                                <p />
                                <div class="media">
                                  <div class="media-left">
                                    <div class="icon is-left has-text-primary">
                                      <FontAwesomeIcon icon={faAddressCard} size="md" />
                                    </div>
                                  </div>
                                  <div class="media-content">
                                    <p class="title is-6">Contact Person Mess:</p>
                                    <p class="subtitle is-6">
                                      {this.state.mess_data.pic_phone} (
                                      {this.state.mess_data.pic_name})
                                    </p>
                                  </div>
                                </div>
                                <p />
                                <div class="media">
                                  <div class="media-left">
                                    <div class="icon is-left has-text-primary">
                                      <FontAwesomeIcon icon={faClock} size="md" />
                                    </div>
                                  </div>
                                  <div class="media-content">
                                    <p class="title is-6">Tanggal Check In:</p>
                                    <p class="subtitle is-6">{finalCheckInDate}</p>
                                  </div>
                                  {this.state.booking_data.staying_type === 1 ? (
                                    <>
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faClock} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Tanggal Check Out:</p>
                                        <p class="subtitle is-6">{finalCheckOutDate}</p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                            {this.state.booking_data.room_name === null ? (
                              <>
                                <div class="media">
                                  {this.state.booking_data.occupant_type === 1 ? (
                                    <>
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faUserFriends} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Jenis Mess:</p>
                                        <p class="subtitle is-6">Single</p>
                                      </div>
                                    </>
                                  ) : this.state.booking_data.occupant_type === 2 ? (
                                    <>
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faUserFriends} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Jenis Mess:</p>
                                        <p class="subtitle is-6">Couple</p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                                <p />
                                {this.state.booking_data.staying_type === 1 ? (
                                  <div class="media">
                                    <div class="media-left">
                                      <div class="icon is-left has-text-primary">
                                        <FontAwesomeIcon icon={faSuitcase} size="md" />
                                      </div>
                                    </div>
                                    <div class="media-content">
                                      <p class="title is-6">Jenis Penempatan:</p>
                                      <p class="subtitle is-6">Visit</p>
                                    </div>
                                  </div>
                                ) : this.state.booking_data.staying_type === 2 ? (
                                  <>
                                    <div class="media">
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faSuitcase} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Jenis Penempatan:</p>
                                        <p class="subtitle is-6">Settle</p>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                <p />
                                <div class="media">
                                  <div class="media-left">
                                    <div class="icon is-left has-text-primary">
                                      <FontAwesomeIcon icon={faClock} size="md" />
                                    </div>
                                  </div>
                                  <div class="media-content">
                                    <p class="title is-6">Tanggal Check In:</p>
                                    <p class="subtitle is-6">{finalCheckInDate}</p>
                                  </div>
                                  {this.state.booking_data.staying_type === 1 ? (
                                    <>
                                      <div class="media-left">
                                        <div class="icon is-left has-text-primary">
                                          <FontAwesomeIcon icon={faClock} size="md" />
                                        </div>
                                      </div>
                                      <div class="media-content">
                                        <p class="title is-6">Tanggal Check Out:</p>
                                        <p class="subtitle is-6">{finalCheckOutDate}</p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                            <p />
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>

                <div class="container has-text-left pb-3 my-3">
                  {latestData.booking_status_id && (
                    <>
                      {latestData.booking_status_id === 1 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-success">
                                  Booking submitted
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Permohonan akan segera ditangani
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-7">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 2 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-success">
                                  On Process
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Permohonan sedang kami ditangani
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-7">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 3 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-danger">
                                  On Waiting List
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Permohonan booking anda sedang masuk waiting list. <br />
                                  Jika setelah 7 hari tidak ada konfirmasi, harap hubungi
                                  <a
                                    href="https://api.whatsapp.com/send/?phone=6285946870938&text&app_absent=0"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    PIC Parastay
                                  </a>
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="subtitle has-text-centered is-size-6">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 4 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-gomess-positivestate">
                                  Done
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Anda akan menempati
                                </p>
                                <p class="subtitle has-text-centered is-size-5">
                                  <b>{this.state.booking_data.room_name}</b>
                                </p>
                                <p class="subtitle has-text-centered is-size-6">
                                  Silahkan hubungi Contact Person Mess dan Check In di tanggal yang
                                  telah ditentukan
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-6">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 5 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-gomess-grey">
                                  Declined
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Maaf, permohonan booking mess anda kami tolak
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-6">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 6 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-gomess-grey">
                                  Settled
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Anda telah menetap di Mess yang ditentukan
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-6">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 7 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-gomess-grey">
                                  Check Out Request Created
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Anda telah membuat permohonan untuk keluar mess
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-7">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 8 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-gomess-grey">
                                  Move Out Request Created
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Anda telah membuat permohonan untuk pindah mess
                                </p>
                                {latestData.booking_log !== null ? (
                                  <p class="has-text-centered is-size-7">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 9 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-gomess-grey">
                                  Move Out Request Approved
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Pengajuan anda diterima, silahkan pindah dari Mess lama menuju
                                  Mess baru:
                                </p>
                                <p class="subtitle has-text-centered is-size-5">
                                  <b>{this.state.booking_data.room_name}</b>
                                </p>
                                <p class="subtitle has-text-centered is-size-6">
                                  Silahkan hubungi Contact Person Mess dan Check In di tanggal yang
                                  telah ditentukan
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-7">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {latestData.booking_status_id === 10 ? (
                        <div class="card has-background-gomess-light mt-5 mb-5 px-5 py-5">
                          <div class="media">
                            <div class="media-content is-align-items-center">
                              <div class="content ">
                                <h1 class="has-text-centered is-size-3 has-text-gomess-grey">
                                  Check Out Request Approved
                                </h1>
                                <p class="subtitle has-text-centered is-size-6">
                                  Pengajuan anda diterima, silahkan Check Out di tanggal yang telah
                                  ditentukan.
                                </p>
                                <p class="subtitle has-text-centered is-size-6">
                                  Terima kasih telah menetap di Mess Paragon!
                                </p>
                                {latestData.booking_log !== "" ? (
                                  <p class="has-text-centered is-size-7">
                                    Comment: <strong>{latestData.booking_log}</strong>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                  <div class="card mt-5">
                    <div class="card-content">
                      <div class="media">
                        <div class="media-content">
                          <Text fontSize="16px" mb="1em" textColor="#B1B1B1">
                            Request ID #{this.state.booking_data.data_id}
                          </Text>
                          <ul class="steps is-dashed is-vertical">
                            {this.state.booking_log_data.map((data, index) => {
                              var options = {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                timeZone: "Asia/Jakarta",
                                timeZoneName: "short",
                              };

                              const formatter = new Intl.DateTimeFormat("id", options);
                              let SDDate = new Date(data.send_datetime);
                              const finalSDDate = formatter.format(SDDate);

                              return (
                                <li key={index} class="steps-segment">
                                  <span class="steps-marker">
                                    <div class="icon is-left has-text-white">
                                      <FontAwesomeIcon icon={faInfo} size="md" />
                                    </div>
                                  </span>
                                  <div class="steps-content">
                                    <Text fontSize="18px" fontWeight="600">
                                      {this.BookingTextStatus(data.booking_status_id)}
                                    </Text>
                                    <Text fontSize="14px" mb="0.5em">
                                      {finalSDDate}
                                    </Text>
                                    {data.booking_log !== "" && (
                                      <Text fontSize="14px" fontWeight="500">
                                        Komentar:{" "}
                                        <Text fontSize="14px" mb="0.5em" fontWeight="400">
                                          {data.booking_log}
                                        </Text>
                                      </Text>
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      </>
    );
  }
}

export default BookingTrackView;
