import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Lato', 'sans-serif'`,
    body: `'Lato', 'sans-serif'`,
  },
  colors: {
    // blue: {
    //   main: '#0C4AC0',
    //   hover: '#0A3EA0',
    //   pressed: '#062560',
    //   focus: 'rgba(12, 74, 192, 0.2)',
    //   surface: '#CEDBF2',
    //   border: '#AEC3EA',
    //   500: '#0C4AC0',
    //   600: '#0A3EA0',
    //   700: '#062560'
    // },
    white: {
      primary: "#FFFFFF",
    },
    gomessLight: {
      primary: "#F1F8FA",
    },
    gomessDanger: {
      primary: "#F5365C",
    },
    gomessWarning: {
      primary: "#fb6340",
    },
    gomessDark: {
      primary: "#08232D",
    },
    gomessBlue: {
      primary: "#30C1FF",
    },
    gomessPink: {
      primary: "#FF8ECB",
    },
    gomessBlack: {
      primary: "#08232E",
    },
    gomessWhite: {
      primary: "#FFFFFF",
    },
    gomessLightPink: {
      primary: "#FFE2F2",
    },
    gomessBabyBlue: {
      primary: "#EAF9FF",
    },
    gomessGrey: {
      primary: "#B1B1B1",
    },
    gomessPositiveState: {
      primary: "#1AAA0D",
    },
    gomessYellowMustard: {
      primary: "#EE930A",
    },
    gomessNeonBlue: {
      primary: "#1660FF",
    },
    gomessPinkRose: {
      primary: "#FF1593",
    },
  },
});

export default theme;
