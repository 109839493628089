import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import "bulma-calendar/dist/css/bulma-calendar.min.css";
import "bulma-calendar/dist/js/bulma-calendar.min.js";
import "bulma/css/bulma.css";
import React from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import { ChakraProvider } from "@chakra-ui/react";
import "./assets/mystyles.scss";

import BaseLayout from "./components/BaseLayout";

import AuthLayout from "./layouts/Auth";
import BookingCar from "./layouts/BookingCar";
import BookingRoom from "./layouts/BookingRoom";
import ContactUs from "./layouts/ContactUs";
import History from "./layouts/History";
import Upcoming from "./layouts/Upcoming";
import HomeLayout from "./layouts/Home";
import Notification from "./layouts/Notification";
import Parastay from "./layouts/Parastay";
import Profile from "./layouts/Profile";
import Report from "./layouts/Report";
import TermsAndConditions from "./layouts/TermsAndConditions";
import theme from "./theme";
import IndexView from "./views/index";
import LoginPage from "./views/pages/login/login";

library.add(fab);

// deployed server
axios.defaults.baseURL = "https://parastay-api.pti-cosmetics.com/index.php/api";

const login = localStorage.getItem("login");

if (login === "true") {
  ReactDOM.render(
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <BaseLayout>
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/home" render={(props) => <HomeLayout {...props} />} />
            <Route path="/booking-room" render={(props) => <BookingRoom {...props} />} />
            <Route path="/booking-car" render={(props) => <BookingCar {...props} />} />
            <Route path="/parastay" render={(props) => <Parastay {...props} />} />
            <Route path="/history" render={(props) => <History {...props} />} />
            <Route path="/upcoming" render={(props) => <Upcoming {...props} />} />
            <Route path="/report" render={(props) => <Report {...props} />} />
            <Route path="/profile" render={(props) => <Profile {...props} />} />
            <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
            <Route
              path="/terms-and-conditions"
              render={(props) => <TermsAndConditions {...props} />}
            />
            <Route path="/notification" render={(props) => <Notification {...props} />} />
            <Route path="/" render={(props) => <IndexView {...props} />} />
            <Redirect from="*" to="/" />
          </Switch>
        </BaseLayout>
      </BrowserRouter>
    </ChakraProvider>,

    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <ChakraProvider>
      <BrowserRouter>
        <BaseLayout>
          <Switch>
            <Route path="/" component={LoginPage} />
          </Switch>
        </BaseLayout>
      </BrowserRouter>
    </ChakraProvider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
